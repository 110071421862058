
@import "./assets/theme/scss/variables.scss";
@import '~bootstrap/scss/bootstrap';

@import "./assets/theme/css/theme.css";
@import "./assets/theme/vendor/remixicon/remixicon.css";
@import "./assets/theme/vendor/boxicons/css/boxicons.min.css";
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap');

html {
	scroll-margin-top: 100px;
	scroll-padding-top: 70px;
}

@media screen and (max-width: 576px) {
	html {
		scroll-margin-top: 50px;
		scroll-padding-top: 50px;
	}
}

body {
	font-family: "Poppins", 'sans-serif';
}

a {
	text-decoration: none;
}

h1{
	font-size: 2rem;
	font-weight: 900;
}

h2 {
	font-size: 1.75rem;
	font-weight: 700;
}

h3 {
	font-size: 1.5rem;
	font-weight: 600;
}

h4 {
	font-size: 1.25rem;
	font-weight: 400;
}

h5 {
	font-size: 1rem;
	font-weight: 400;
}

h6 {
	font-size: 0.85rem;
	font-weight: 400;
}

.form-control:focus {
	border-color: $primary;
	box-shadow: none;
}

.bg-light {
	background-color: #F5F5F5!important;
}

section {
	padding: 30px 0;
}

// overload tel input

.iti {
	display: block!important;
}

.iti__selected-flag.dropdown-toggle {
	background-color: #F5F5F5;
}